import { useEffect } from "react";
import { useSelector } from "react-redux";
import NavComponent from "./Nav";

const Layout = ({ children, bgwhite = false, header = true, noPad = false, actionButtons = [], className }) => {
    const state = useSelector(state => state);
    const open = state.global.navState;
    const pageTitle = state.global.title;

    return (
        <div className="flex bg-white">
            <NavComponent />
            <div className={`${open ? 'ml-[16rem]' : 'ml-[5rem]'} h-screen grow flex flex-col overflow-hidden scroll-smooth bg-neutral-200 duration-300`}>
                {header && (
                    <div className="bg-white p-6 gap-6 h-20">
                        <div className="flex flex-row justify-between">
                            <div className="page-title font-proxima-bold">
                                {pageTitle}
                            </div>
                            <div className="flex flex-row">
                                {actionButtons && actionButtons.map((btn, idx) => {
                                    return (
                                        <div key={idx} className="mr-2">
                                            {btn}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
                <div className={`${bgwhite && 'bg-white'} ${className}`}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Layout;