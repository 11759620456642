export const getProgress = (program) => {
    const products = getProductsList(program);
    const count = products.filter(p => p.checked).length;
    const total = count / products.length;
    return isNaN(total * 100) ? 0 : (total * 100).toFixed() ;
}

export const getColData = (program, index) => {
    const products = getProductsList(program);
    const list = products.filter(p => p.index == index && p.value);
    return list.length > 0 && list;
}

export const checkColData = (program, col) => {
    const products = getProductsList(program);
    const list = products.filter(p => p.index == col);
    return !(list.filter(p => !p.checked).length > 0);
}

const getProductsList = (program) => {
    let list = [];
    program?.products?.forEach(group => {
        group.data.forEach((product, index) => {
            product.application.map(item => {
                const data = {
                    product: product.product, 
                    index, 
                    group: group.type,
                    ...item
                }
                list = [...list, data];
            });
        });
    });

    return list;
}