import { useEffect, useState } from 'react';
import Layout from "@/components/Layout";
import TestBoxComponent from "@/components/dashboard/TestBoxComponent";
import RecommendationsComponent from "@/components/dashboard/RecommendationsComponent";
import DashboardProgramsComponent from "@/components/dashboard/DashboardProgramsComponent";
import { useRouter } from "next/router";

const Index = () => {
    const [testCounts, setTestCounts] = useState(null);
    const [programs, setPrograms] = useState(null);
    const router = useRouter();

    useEffect(() => {
      router.replace("/jobs");
    }, []);

    return (
        <Layout>
            <div className="flex space-x-4 m-4">
                <TestBoxComponent testCounts={testCounts} setTestCounts={setTestCounts} />
                <RecommendationsComponent />
            </div>
            <DashboardProgramsComponent programs={programs} />
        </Layout>
    );
}

export default Index;
